<template>
  <v-card>
    <v-card-title>
      <form>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="4">
            <v-autocomplete
              label="Tahun"
              :items="years"
              item-value="years"
              v-model="cari.tahun"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-autocomplete
              :items="options"
              color="white"
              item-text="nm_jadwal"
              item-value="id"
              label="Gelombang"
              v-model="cari.id_gelombang"
            ></v-autocomplete>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-btn @click="show()" class="mr-4" color="primary">
              <v-icon left> mdi-magnify </v-icon>Show
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="dataPst"
      :search="search"
      item-key="id_pst"
      class="elevation-1"
    >
      <template v-slot:[`top`]>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
          append-icon="mdi-magnify"
        >
        </v-text-field>
        <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="dark">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Close</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text>Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-subheader>Peserta Profil</v-subheader>
            <v-col cols="12" class="d-flex align-center">
              <v-avatar size="99" class="ma-1" tile>
                <img :src="ditail.foto_upload" />
              </v-avatar>
            </v-col>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.nm_pst"
                      label="Nama Peserta"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.nik"
                      label="Nomor Induk Kependudukan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.tempat_lahir"
                      label="Tempat Lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.tanggal_lahir"
                      label="Tanggal Lahir"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="agama"
                      item-value="id_agama"
                      color="blue"
                      item-text="name"
                      label="Agama"
                      v-model="ditail.agama"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.jenis_kelamin"
                      label="Jenis Kelamin"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.pekerjaan"
                      label="Pekerjaan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.no_telp"
                      label="No Telepon"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="ditail.alamat"
                      label="Alamat"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-subheader>Asal Pendidikan</v-subheader>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="asalPendidikan"
                      item-text="name"
                      item-value="id"
                      label="Janis Daftar"
                      v-model="ditail.jenis_daftar"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.nisn_nim"
                      label="NIM Asal/NIS"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                    v-if="ditail.jenis_daftar === 'A'"
                  >
                    <v-text-field
                      v-model="ditail.jenjang_pendidikan"
                      label="Jenjang Pendidikan"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="ditail.nm_sekolah_pt"
                      label="Asal Sekolah / PT"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field
                      v-model="ditail.alamat_sekolah_pt"
                      label="Alamat Sekolah / PT"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-subheader>Peminatan Program Studi</v-subheader>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="kls"
                      color="white"
                      item-text="name"
                      item-value="id"
                      label="Kelas"
                      v-model="ditail.kelas"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="prodies"
                      persistent-hint
                      color="white"
                      item-text="nama_program_studi"
                      item-value="kode_program_studi"
                      label="Pilihan 1"
                      v-model="ditail.pilihan_1"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-autocomplete
                      :items="prodies"
                      persistent-hint
                      color="white"
                      item-text="nama_program_studi"
                      item-value="kode_program_studi"
                      label="Pilihan 2"
                      v-model="ditail.pilihan_2"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="open" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Insert Data Peserta To CBT</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="d-flex" cols="12" sm="4">
                    <v-autocomplete
                      label="Kelas CBT"
                      :items="dataCbt"
                      item-text="nama_kelas"
                      item-value="id_kelas"
                      v-model="userCbt.kelas_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="userCbt.nm_pst"
                      label="Nama Peserta"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="open = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="insertToCbt"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.cbt`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="toCbt(item)"
              class="sm-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Insert To CBT</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="item.flag_lulus === 'L'"
              class="ma-2"
              fab
              dark
              x-small
              color="blue lighten-2"
            >
              <v-icon @click="gagal(item)">mdi-thumb-up</v-icon>
            </v-btn>
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-else
              class="ma-2"
              fab
              dark
              x-small
              color="red lighten-2"
            >
              <v-icon @click="lulus(item)">mdi-thumb-down</v-icon>
            </v-btn>
          </template>
          <span>Update Data Lulus</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.jenisDaftar`]="{ item }">
        <v-chip :color="getColor(item.jenis_daftar)" x-small dark>{{
          getParamJenisDaftar(item.jenis_daftar)
        }}</v-chip>
      </template>
      <template v-slot:[`item.kelases`]="{ item }">
        <span v-if="item.kelas === 'R'">Reguler</span>
        <span v-else>Khusus</span>
      </template>
      <template v-slot:[`item.detail`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="ditailItem(item)"
              class="sm-2"
              fab
              dark
              x-small
              color="indigo"
            >
              <v-icon small>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Show Detail Pst</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "dataPeserta",
  data: () => ({
    dialog: false,
    pesertaIndex: -1,
    ditailedIndex: -1,
    open: false,
    search: "",
    kelas: "",
    cari: {
      tahun: "",
      id_gelombang: ""
    },
    userCbt: {
      nama: "",
      nim: "",
      email: "",
      jenis_kelamin: "",
      kelas_id: ""
    },
    ditail: {
      nm_pst: "",
      nik: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      agama: "",
      jenis_kelamin: "",
      pekerjaan: "",
      no_telp: "",
      alamat: "",
      jenis_daftar: "",
      jenjang_pendidikan: "",
      nm_sekolah_pt: "",
      alamat_sekolah_pt: "",
      kelas: "",
      pilihan_1: "",
      pilihan_2: ""
    },
    agama: [
      { name: "Islam", id_agama: "1" },
      { name: "Katolik", id_agama: "3" },
      { name: "Protestan", id_agama: "2" },
      { name: "Hindu", id_agama: "4" },
      { name: "Budha", id_agama: "5" },
      { name: "LainNya", id_agama: "99" }
    ],
    kls: [
      { name: "Khusus", id: "K" },
      { name: "Reguler", id: "R" }
    ],
    asalPendidikan: [
      { name: "Mahasiswa Baru", id: "B" },
      { name: "Mahasiswa Pindahan", id: "P" },
      { name: "Mahasiswa Alih Jenjang", id: "A" },
      { name: "Mahasiswa Pasca Sarjana", id: "X" }
    ]
  }),
  created() {
    this.loadJadwal();
    this.loadProdi();
  },
  computed: {
    ...mapGetters("peserta", ["gel"]),
    ...mapGetters("peserta", ["dataPst"]),
    ...mapGetters("peserta", ["getCbt"]),
    ...mapGetters("reg", ["prodi"]),
    prodies() {
      return Object.keys(this.prodi).map(k => {
        let obj = this.prodi[k];
        return obj;
      });
    },
    options() {
      return Object.keys(this.gel).map(k => {
        let obj = this.gel[k];
        return obj;
      });
    },
    dataCbt() {
      return Object.keys(this.getCbt).map(k => {
        let obj = this.getCbt[k];
        return obj;
      });
    },
    years() {
      let year = new Date().getFullYear();
      return Array.from(
        {
          length: year - 2018
        },
        (value, index) => 2019 + index
      );
    },
    headers() {
      return [
        {
          text: "Detail",
          value: "detail",
          sortable: false
        },
        {
          text: "Nama Peserta",
          value: "nm_pst"
        },
        {
          text: "Kelas",
          value: "kelases"
        },
        {
          text: "Prodi",
          value: "nama_program_studi"
        },
        {
          text: "Jenis Daftar",
          value: "jenisDaftar"
        },
        {
          text: "No Ujian",
          value: "nomor_ujian"
        },
        {
          text: "Kode Daftar Ulang",
          value: "kode_daftar_ulang"
        },
        {
          text: "Aksi",
          value: "cbt",
          sortable: false
        }
      ];
    }
  },
  methods: {
    ...mapActions("peserta", ["loadJadwal"]),
    ...mapActions("reg", ["loadProdi"]),
    getColor(jenis_daftar) {
      if (jenis_daftar === "B") return "red";
      else if (jenis_daftar === "A") return "orange";
      else if (jenis_daftar === "P") return "red lighten-2";
      else if (jenis_daftar === "X") return "blue";
      else return "green";
    },
    getParamJenisDaftar(value) {
      if (value === "B") return "Mahasiswa Baru";
      else if (value === "A") return "Mahasiswa Ampulan";
      else if (value === "P") return "Mahasiswa Pindahan";
      else if (value === "X") return "Mahasiswa S2";
    },
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        formData.append(key, obj[key]);
      }
      return formData;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search) !== -1
      );
    },
    show() {
      let formData = this.formData(this.cari);
      this.$store.dispatch("peserta/loadDataPst", formData);
    },
    toCbt(item) {
      this.pesertaIndex = this.dataPst.indexOf(item);
      this.userCbt = Object.assign({}, item);
      this.$store.dispatch("peserta/loadCbt");
      this.open = true;
    },
    insertToCbt() {
      //let formCbt=this.formData(this.userCbt);
      let formCbt = {
        nama: this.userCbt.nm_pst,
        nim: this.userCbt.nomor_ujian,
        email: this.userCbt.email,
        jenis_kelamin: this.userCbt.jenis_kelamin,
        kelas_id: this.userCbt.kelas_id
      };
      this.$store.dispatch("peserta/addCbt", formCbt).then(() => {
        this.close();
      });
    },
    close() {
      this.open = false;
    },
    lulus(item) {
      let kodeDaftrar = Math.random()
        .toString(36)
        .substring(2, 15);
      let formLulus = {
        id_pst: item.id_pst,
        flag_lulus: "L",
        kode_daftar_ulang: kodeDaftrar
      };
      this.$store.dispatch("peserta/updateLulus", formLulus).then(() => {
        this.show();
      });
    },
    gagal(item) {
      let formLulus = {
        id_pst: item.id_pst,
        flag_lulus: null,
        kode_daftar_ulang: null
      };
      this.$store.dispatch("peserta/updateLulus", formLulus).then(() => {
        this.show();
      });
    },
    ditailItem(item) {
      this.ditailedIndex = this.dataPst.indexOf(item);
      this.ditail = Object.assign({}, item);
      this.dialog = true;
    }
  }
};
</script>
